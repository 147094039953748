import React, {useEffect} from "react";
import Seo from "../../components/seo"
import Layout from "../../components/layout"

import About from '../../components/Emprendimiento/about'
import Units from '../../components/Emprendimiento/units'

import { connect } from "react-redux"

import { useParams } from "@reach/router"
import {getDevelopmentAction, getUnitsDevelopmentAction} from '../../redux/developmentsDucks'
import { getName,getFakeAddress,getLocation} from "../../helpers/helper.developments";
import { getCover } from "../../helpers/helper.rendering";


const ClientSideOnlyLazy = React.lazy(() =>
  import("../../components/Emprendimiento/main")
)

const Development = (props) => {


  const {loading} = props
  const {loading_unities} = props
  const {api_key} = props
  const {dispatch} = props
  const {id} = props
  // const {serverData} = props

  useEffect(() => {
    if(api_key && id){
      dispatch(getDevelopmentAction(id))
      dispatch(getUnitsDevelopmentAction(id))
    }
  },[api_key,id])

  const isSSR = typeof window === "undefined"

	return (
        <Layout>
            {/* <Seo 
              title={"Conocé " + getName(serverData)}
              meta={"Encontrá el emprendimiento que estabas buscando. " + getFakeAddress(serverData) + " en " + getLocation(serverData)} 
              description={"Encontrá el emprendimiento que estabas buscando. " + getFakeAddress(serverData) + " en " + getLocation(serverData)} 
              image={getCover(serverData.photos)?.original} /> */}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <ClientSideOnlyLazy />
              </React.Suspense>
            )}
            <About />
            <Units />
        </Layout> 
    )
}
export default connect(state => ({
    loading: state.developments.loading,
    loading_unities: state.developments.loading_unities,
    api_key: state.settings.keys.tokko
}),null)(Development);

// export async function getServerData(context) {
//   try {
//     const res = await fetch(`https://api-mh.mediahaus.com.ar/api/v1/tokko/developments/${context.params.id}?cache=true`,{headers: {"X-TOKKO-KEY": "4aeb6e6d5e8f5f041734390ce1a2a65238328ae9"}})
//     if (!res.ok) {
//       throw new Error(`Response failed`)
//     }
//     return {
//       props: await res.json(),
//     }
//   } catch (error) {
//     return {
//       status: 500,
//       headers: {},
//       props: {}
//     }
//   }
// }